/* global XMLHttpRequest, WebSocket */
import dayjs from 'dayjs'
import Pikaday from 'pikaday'
import hyper from 'hyperhtml'
import ErrorRivet1 from './components/Error'
import ErrorRivet2 from './components/ErrorRivet2'

const PROD_REGEX = /\/\w+-prd\/.*/
const DATE_REGEX_1 = /^\d{1,2}\/\d{1,2}\/\d{4}$/
const DATE_REGEX_2 = /^\d{1,2}-\d{1,2}-\d{4}$/

const useRivet2 = !!window.Rivet
const Error = useRivet2 ? ErrorRivet2 : ErrorRivet1

let loading = document.getElementById('loading')
if (!loading && document.body) {
  loading = document.createElement('div')
  loading.setAttribute('id', 'loading')
  loading.style.position = 'fixed'
  loading.style.display = 'none'
  if (useRivet2) {
    loading.setAttribute('class', 'rvt-loader rvt-loader--sm')
    loading.style.top = '5.5rem'
    loading.style.right = '0.5rem'
  } else {
    loading.setAttribute('class', 'rvt-loader')
    loading.setAttribute('aria-label', 'Content loading')
    loading.style.top = '75px'
    loading.style.right = '5px'
  }

  document.body.appendChild(loading)
}

class EventStream {
  constructor () {
    this.listeners = []
  }

  next (o) {
    if (!this.listeners) throw new Error('EventStream completed')
    this.last = o
    delete this.lastError
    this.listeners.forEach(s => {
      if (s.next) {
        try {
          s.next(o)
        } catch (e) {
          if (s.error) {
            s.error({ error: e })
          } else console.error(e)
        }
      }
    })
  }

  error (e) {
    if (!this.listeners) throw new Error('EventStream completed')
    delete this.last
    this.lastError = e
    this.listeners.forEach(s => {
      if (s.error) {
        try {
          s.error(e)
        } catch (e2) {
          console.error(e, e2)
        }
      }
    })
  }

  complete () {
    const { listeners } = this
    delete this.listeners
    if (listeners) {
      listeners.forEach(s => {
        if (s.complete) {
          try {
            s.complete()
          } catch (e) {
            console.error(e)
          }
        }
      })
    }
  }

  subscribe (s) {
    if (typeof s === 'function') s = { next: s }

    if (this.last && s.next) s.next(this.last)
    if (this.lastError && s.error) s.error(this.lastError)
    if (!this.listeners && s.complete) s.complete()

    if (this.listeners) this.listeners.push(s)

    const children = []
    return {
      add: c => children.push(c),
      remove: c => {
        const i = children.indexOf(c)
        if (i !== -1) children.splice(i, 1)
      },
      unsubscribe: () => {
        const { listeners } = this
        if (listeners) {
          const i = listeners.indexOf(s)
          if (i !== -1) listeners.splice(i, 1)
        }
        children.forEach(c => c.unsubscribe())
      }
    }
  }
}

const environment = {
  applicationUrl: null,
  devMode: false,
  authParams: {}
}
const event$ = new EventStream()

function ajaxStart () {
  if (loading) loading.style.display = 'block'
}

function ajaxStop () {
  if (loading) loading.style.display = 'none'
}

function ajaxError (err, reload = false) {
  const response = err.xhr

  // skip dialog for non-server errors
  if (response.status < 400) return

  let errorData
  if (response.responseText && response.responseText.indexOf('<html') !== -1) {
    const responseElement = document.createElement('html')
    responseElement.innerHTML = response.responseText
    const responseScriptTags = responseElement.querySelectorAll('body>script')
    if (responseScriptTags) {
      for (let i = responseScriptTags.length - 1; i >= 0; i--) {
        const responseScriptTag = responseScriptTags.item(i)
        try {
          const scriptTagContent = responseScriptTag.textContent
          if (!scriptTagContent) {
            continue
          }
          if (scriptTagContent.startsWith('renderError({') && scriptTagContent.endsWith('})')) {
            errorData = JSON.parse(scriptTagContent.slice(12, scriptTagContent.length - 1))
            break
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  } else {
    try {
      errorData = JSON.parse(response.responseText)
    } catch (e) {
      console.log(e)
    }
  }

  if (!errorData) {
    errorData = { status: response.status }
  }

  errorDialog(errorData, reload)
}

function createSearchParams (params) {
  const searchParams = new URLSearchParams()
  let empty = true
  if (params) {
    for (const n in params) {
      if (!environment.authParams[n]) {
        const v = params[n]
        if (Array.isArray(v)) {
          v.forEach(a => {
            searchParams.append(n, a)
            empty = false
          })
        } else if (v) {
          searchParams.append(n, v)
          empty = false
        }
      }
    }
  }

  for (const n in environment.authParams) {
    searchParams.append(n, environment.authParams[n])
    empty = false
  }

  if (empty) return null
  else return searchParams
}

function createQueryString (params) {
  const searchParams = createSearchParams(params)
  if (searchParams) return searchParams.toString()
  else return null
}

function getUrl (url, params, absolute) {
  if (!absolute && environment.applicationUrl) {
    let base = environment.applicationUrl
    if (base.lastIndexOf('/') !== base.length - 1) base += '/'
    url = new URL(url, base).toString()
  }

  const i = url.indexOf('?')
  if (i !== -1) {
    if (!params) params = {}

    for (const [key, value] of new URLSearchParams(url.substring(i + 1))) {
      if (!params[key]) params[key] = value
    }
    url = url.substring(0, i)
  }

  const query = createSearchParams(params)
  if (query) return url + '?' + query.toString()
  else return url
}

function doAjax (method, url, data, contentType, absolute, reload = false) {
  const rv$ = new EventStream()
  rv$.subscribe({
    next: a => event$.next(a),
    error: e => {
      ajaxStop()
      ajaxError(e, reload)
    },
    complete: () => ajaxStop()
  })
  const xhr = new XMLHttpRequest()

  if (environment.devMode) {
    data = { data, method }
    method = 'GET'
  }

  if (method === 'POST' || method === 'PUT') {
    xhr.open(method, getUrl(url, null, absolute))
    if (contentType) xhr.setRequestHeader('Content-Type', contentType)
  } else {
    xhr.open(method, getUrl(url, data, absolute))
    data = null
  }

  let responseType
  xhr.onreadystatechange = () => {
    switch (xhr.readyState) {
      case 2:
        xhr.getAllResponseHeaders().trim().split(/[\r\n]+/).forEach(h => {
          if (h.toLowerCase().indexOf('content-type: ') === 0) responseType = h.substring(14)
        })
        break

      case 4:
        if (xhr.status >= 400) rv$.error({ xhr, error: new Error(xhr.status + ' ' + xhr.statusText) })
        else if (responseType && responseType.indexOf('application/json') === 0) {
          rv$.next(JSON.parse(xhr.responseText))
        } else rv$.next(xhr)
        rv$.complete()
        break
    }
  }

  ajaxStart()
  xhr.send(data)
  return rv$
}

const connectedWebSockets = {}

function webSocket (url, message, pingInterval = 300000, absolute) {
  const wsurl = getUrl(url, null, absolute).replace(/^http/, 'ws')
  let socket = connectedWebSockets[wsurl]

  if (!socket || socket.readyState > WebSocket.OPEN) {
    connectedWebSockets[wsurl] = socket = new WebSocket(wsurl)
    let ping = 0

    socket.addEventListener('message', d => {
      const s = JSON.parse(d.data)
      if (s) {
        if (s.ping) {
          if (ping !== s.ping) { console.error('ping echo mismatch', ping, s) }
        } else if (s.error) { errorDialog(s.error) } else { event$.next(s) }
      }
    })

    if (pingInterval > 0) {
      const pingTimer = setInterval(function () {
        if (this.readyState === WebSocket.OPEN) this.send(JSON.stringify({ ping: ++ping }))
      }.bind(socket), pingInterval)
      socket.onclose = () => clearInterval(pingTimer)
    }
  }

  if (message) {
    if (socket.readyState === WebSocket.CONNECTING) {
      socket.addEventListener('open', function () {
        socket.send(JSON.stringify(message))
      })
    } else { socket.send(JSON.stringify(message)) }
  }
}

function isProduction () {
  return PROD_REGEX.test(window.location.pathname)
}

function filterCurrentDateTime (currentDateTime) {
  if (!currentDateTime) return null

  if (DATE_REGEX_1.test(currentDateTime) ||
      DATE_REGEX_2.test(currentDateTime) ||
      !isNaN(new Date(currentDateTime.valueOf()))) return currentDateTime

  return null
}

function isDateOnlyOverriden () {
  const { currentDateTime } = environment.authParams
  if (!currentDateTime) return false

  return DATE_REGEX_1.test(currentDateTime) ||
      DATE_REGEX_2.test(currentDateTime)
}

function isCurrentDateOverriden () {
  return !!environment.authParams.currentDateTime
}

function getCurrentDate () {
  const { currentDateTime } = environment.authParams
  if (!currentDateTime) return new Date()

  const dateVal = new Date(currentDateTime)
  if (isDateOnlyOverriden()) {
    const timeVal = new Date()
    return new Date(
      dateVal.getFullYear(), dateVal.getMonth(), dateVal.getDate(),
      timeVal.getHours(), timeVal.getMinutes(), timeVal.getSeconds(), timeVal.getMilliseconds()
    )
  } else {
    return dateVal
  }
}

function init (env) {
  const { authParams } = environment
  const a = {}
  for (const n in env) {
    if (n === 'authParams') {
      for (const m in env.authParams) authParams[m] = env.authParams[m]
    } else if (Object.prototype.hasOwnProperty.call(environment, n)) {
      environment[n] = env[n]
    } else {
      a[n] = env[n]
    }
  }

  let currentDateTime
  if (!isProduction()) {
    if (authParams.currentDateTime) currentDateTime = filterCurrentDateTime(authParams.currentDateTime)
    else currentDateTime = filterCurrentDateTime(new URLSearchParams(window.location.search).get('currentDateTime'))
  }

  if (currentDateTime) authParams.currentDateTime = currentDateTime
  else if (authParams.currentDateTime) delete authParams.currentDateTime

  event$.next(a)
}

function hash (s) {
  let h = 0
  if (s.length === 0) { return s }
  for (let i = 0; i < s.length; i++) { h = (h << 5) - h + s.charCodeAt(i) | 0 }
  return h.toString(16)
}

function shortuid () {
  const m = Date.now() % 4194304
  const r = Math.floor(Math.random() * 12582911) + 4194304
  return (m + r).toString(16)
}

function hasChanged (a, b) {
  if (a === b || (typeof b === 'undefined')) {
    return false
  } else if ((a === null && b !== null) || (a !== null && b === null)) {
    return true
  } else if (Array.isArray(a)) {
    if (!Array.isArray(b)) {
      return true
    }
    if (a.length !== b.length) {
      return true
    }
    for (const i in a) {
      if (hasChanged(a[i], b[i])) return true
    }
    return false
  } else if (typeof a === 'object') {
    if (typeof b !== 'object') return true
    for (const i in a) {
      if (hasChanged(a[i], b[i])) return true
    }
    return false
  } else return true
}

function copyChanges (a, b) {
  if (a === b) return true // special case, for initialization
  let changed = false
  if (b) {
    for (const i in a) {
      if (hasChanged(a[i], b[i])) {
        a[i] = b[i]
        changed = true
      }
    }
  }
  return changed
}

function errorDialog (data, reload = false) {
  const activeElement = document.activeElement
  const body = document.querySelector('body')
  const scopeWrapper = document.createElement('div')
  scopeWrapper.className = 'rvt-scope'

  const dialog = document.createElement('section')
  dialog.setAttribute('id', 'error-dialog')
  if (useRivet2) {
    dialog.setAttribute('class', 'rvt-dialog')
    dialog.setAttribute('id', 'error-modal')
    dialog.setAttribute('aria-labelledby', 'error-modal-title')
    dialog.setAttribute('data-rvt-modal', 'error-modal')
  } else {
    dialog.setAttribute('class', 'modal')
  }
  dialog.setAttribute('role', 'dialog')
  dialog.setAttribute('tabindex', '-1')
  dialog.addEventListener('keydown', e => {
    if (e.key === 'Escape') {
      reload ? handleModalCloseReload(e) : handleModalClose(activeElement, scopeWrapper, body)
    }
    if (e.key === 'Tab') {
      handleTab(e, dialog)
    }
  })

  const props = {}
  for (const n in data) props[n] = data[n]
  props.modal = true
  props.closeModal = (e) => reload ? handleModalCloseReload(e) : handleModalClose(activeElement, scopeWrapper, body)
  props.reload = reload
  dialog.appendChild(Error(props))
  dialog.style.display = 'block'

  scopeWrapper.appendChild(dialog)
  body.appendChild(scopeWrapper)
  dialog.focus()
}

function handleModalCloseReload (e) {
  e.stopPropagation()
  e.preventDefault()
  window.location.reload()
}

function handleModalClose (activeElement, dialog, body) {
  body.removeChild(dialog)
  window.requestAnimationFrame(
    () => window.requestAnimationFrame(() => {
      if (isFocusable(activeElement)) {
        activeElement.focus()
      } else {
        const focusableElements = getFocusableElements(activeElement)
        if (focusableElements && focusableElements.length) {
          focusableElements[0].focus()
        }
      }
    })
  )
}

function handleTab (event, element) {
  const { shiftKey, target } = event
  const focusableEl = getFocusableElements(element)
  const firstFocusableEl = focusableEl[0]
  const lastFocusableEl = focusableEl[focusableEl.length - 1]
  const isTargetFirst = target === firstFocusableEl
  const isTargetLast = target === lastFocusableEl
  const isTargetSelf = target === element

  event.stopPropagation()
  if ((isTargetFirst || isTargetSelf) && shiftKey) {
    event.preventDefault()
    lastFocusableEl && lastFocusableEl.focus()
  } else if ((isTargetLast || isTargetSelf) && !shiftKey) {
    event.preventDefault()
    firstFocusableEl && firstFocusableEl.focus()
  }
}

function isFocusable (element) {
  switch (element.tagName) {
    case 'A':
      return element.href && element.href.length
    case 'INPUT':
      return element.type !== 'radio' || element.checked
    case 'BUTTON':
    case 'SELECT':
    case 'TEXTAREA':
      return true
    default:
      return element.tabindex && element.tabindex !== '-1'
  }
}

const focusableElementList = 'button, a[href], input[type="radio"]:checked, input:not([type="radio"]), select, textarea, [tabindex]:not([tabindex="-1"])'

function getFocusableElements (element) {
  const focusable = element.querySelectorAll(focusableElementList)
  const focusableArray = Array.prototype.slice.call(focusable)
  const enabledFocusable = focusableArray.filter(el => {
    return !el.disabled
  })
  return enabledFocusable
}

function ajaxGet (url, params, reload = false) {
  return doAjax('GET', url, params, null, null, reload)
}

function ajaxPost (url, data, reload = false) {
  return doAjax('POST', url, JSON.stringify(data), 'application/json', null, reload)
}

function ajaxFile (url, data, reload = false) {
  return doAjax('POST', url, data, null, null, reload)
}

function ajaxPut (url, data, reload = false) {
  return doAjax('PUT', url, JSON.stringify(data), 'application/json', null, reload)
}

function ajaxDelete (url, params, reload = false) {
  return doAjax('DELETE', url, params, null, null, reload)
}

function updateLocation (url, params, absolute) {
  window.history.pushState({}, '', getUrl(url, params, absolute))
}

function getEnvironment () {
  return environment
}

function getParam (name) {
  return environment.authParams[name]
}

function setParam (name, value) {
  if (value == null) { delete environment.authParams[name] } else { environment.authParams[name] = value }
}

function popup (url, params, absolute) {
  window.open(getUrl(url, params, absolute))
}

function navigateTo (url, params, absolute) {
  window.location = getUrl(url, params, absolute)
}

function subscribe (o) {
  return event$.subscribe(o)
}

export default window.iu = {
  init,
  getEnvironment,
  getParam,
  setParam,
  isProduction,
  getCurrentDate,
  isCurrentDateOverriden,
  createSearchParams,
  createQueryString,
  getUrl,
  doAjax,
  ajaxGet,
  ajaxPost,
  ajaxFile,
  ajaxPut,
  ajaxDelete,
  ajaxError,
  hash,
  shortuid,
  hasChanged,
  copyChanges,
  webSocket,
  updateLocation,
  dayjs,
  Pikaday,
  Error,
  errorDialog,
  popup,
  navigateTo,
  EventStream,
  subscribe,
  hyper
}
