import dayjs from 'dayjs'

var statusCodes = {
  100: 'CONTINUE',
  101: 'SWITCHING PROTOCOLS',
  200: 'OK',
  201: 'CREATED',
  202: 'ACCEPTED',
  203: 'NON AUTHORITATIVE INFORMATION',
  204: 'NO CONTENT',
  205: 'RESET CONTENT',
  206: 'PARTIAL CONTENT',
  300: 'MULTIPLE CHOICES',
  301: 'MOVED PERMANENTLY',
  302: 'FOUND',
  303: 'SEE OTHER',
  304: 'NOT MODIFIED',
  305: 'USE PROXY',
  307: 'TEMPORARY REDIRECT',
  400: 'BAD REQUEST',
  401: 'UNAUTHORIZED',
  402: 'PAYMENT REQUIRED',
  403: 'FORBIDDEN',
  404: 'NOT FOUND',
  405: 'METHOD NOT ALLOWED',
  406: 'NOT ACCEPTABLE',
  407: 'PROXY AUTHENTICATION REQUIRED',
  408: 'REQUEST TIMEOUT',
  409: 'CONFLICT',
  410: 'GONE',
  411: 'LENGTH REQUIRED',
  412: 'PRECONDITION FAILED',
  413: 'REQUEST ENTITY TOO LARGE',
  414: 'REQUEST URI TOO LONG',
  415: 'UNSUPPORTED MEDIA TYPE',
  416: 'REQUESTED RANGE NOT SATISFIABLE',
  417: 'EXPECTATION FAILED',
  500: 'INTERNAL SERVER ERROR',
  501: 'NOT IMPLEMENTED',
  502: 'BAD GATEWAY',
  503: 'SERVICE UNAVAILABLE',
  504: 'GATEWAY TIMEOUT',
  505: 'HTTP VERSION NOT SUPPORTED'
}

export function shortuid () {
  var m = Date.now() % 4194304
  var r = Math.floor(Math.random() * 12582911) + 4194304
  return (m + r).toString(16)
}

function labelValue (label, value) {
  const id = shortuid()
  const e = document.createElement('section')
  const l = document.createElement('label')
  const s = document.createElement('span')
  s.setAttribute('id', id)
  s.textContent = value
  l.setAttribute('for', id)
  l.textContent = label
  e.appendChild(l)
  e.appendChild(s)
  return e
}

export default function (props) {
  var headerText
  if (props.status) {
    if (statusCodes[props.status]) { headerText = props.status + '- ' + statusCodes[props.status] } else { headerText = props.status }
  } else { headerText = 'SCRIPT ERROR' }

  var requestNumber
  if (props.requestNumber) requestNumber = labelValue('Request #', props.requestNumber)

  var nodeId
  if (props.nodeId) nodeId = labelValue('Node', props.nodeId)

  let message
  if (props.message || props.reload) {
    let errorSection
    let messageClass = 'rvt-alert'
    if (props.severe) {
      messageClass += ' rvt-alert--error'
      errorSection = document.createElement('p')
      errorSection.setAttribute('class', 'alert__message')
      errorSection.appendChild(document.createTextNode('If you continue to receive this message, please contact the '))
      const supportLink = document.createElement('a')
      supportLink.href = 'http://kb.iu.edu/data/abxl.html'
      supportLink.textContent = 'IU Support Center'
      errorSection.appendChild(supportLink)
      errorSection.appendChild(document.createTextNode(', and include the information referenced on this page.'))
    }
    messageClass += ' rvt-m-bottom-md'

    let reloadMessage
    if (props.reload) {
      reloadMessage = document.createElement('p')
      reloadMessage.setAttribute('class', 'alert__message rvt-m-top-xs')
      reloadMessage.appendChild(document.createTextNode('Reload page to continue working.'))
    }

    message = document.createElement('div')
    message.setAttribute('class', messageClass)
    message.setAttribute('role', 'alert')
    message.setAttribute('aria-labelledby', 'error-alert-title')
    const icon = document.createElement('div')
    icon.setAttribute('class', 'icon')
    message.appendChild(icon)
    const alertContent = document.createElement('div')
    const alertTitle = document.createElement('h1')
    alertTitle.setAttribute('class', 'alert__title')
    alertTitle.setAttribute('id', 'error-alert-title')
    alertTitle.textContent = props.message ? props.message : 'Unidentified Error'
    alertContent.appendChild(alertTitle)
    if (errorSection) alertContent.appendChild(errorSection)
    if (reloadMessage) alertContent.appendChild(reloadMessage)
    message.appendChild(alertContent)
  }

  let closeButton
  if (props.modal && props.closeModal) {
    closeButton = document.createElement('button')
    closeButton.setAttribute('class', `rvt-button ${props.reload ? '' : 'rvt-modal__close'}`)
    closeButton.onclick = props.closeModal
    const closeSrLabel = document.createElement('span')
    if (!props.reload) {
      closeSrLabel.setAttribute('class', 'rvt-sr-only')
    }
    closeSrLabel.textContent = props.reload ? 'Reload page' : 'Close'
    closeButton.appendChild(closeSrLabel)
    if (!props.reload) {
      const closeIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
      closeIcon.setAttribute('role', 'img')
      closeIcon.setAttribute('width', '16')
      closeIcon.setAttribute('height', '16')
      closeIcon.setAttribute('viewBox', '0 0 16 16')
      const closeIconPath = document.createElementNS('http://www.w3.org/2000/svg', 'path')
      closeIconPath.setAttribute('fill', 'currentColor')
      closeIconPath.setAttribute('d', 'M9.41,8l5.29-5.29a1,1,0,0,0-1.41-1.41L8,6.59,2.71,1.29A1,1,0,0,0,1.29,2.71L6.59,8,1.29,13.29a1,1,0,1,0,1.41,1.41L8,9.41l5.29,5.29a1,1,0,0,0,1.41-1.41Z')
      closeIcon.appendChild(closeIconPath)
      closeButton.appendChild(closeIcon)
    }
  }

  const errorInfo = document.createElement('section')
  errorInfo.setAttribute('id', 'errorInfo')
  errorInfo.setAttribute('class', props.modal ? 'modal__inner' : 'Error md-all-sm')

  const errorHeader = document.createElement('header')
  errorHeader.setAttribute('class', props.modal ? 'modal__header' : 'Error__header')
  const errorTitle = document.createElement('h1')
  errorTitle.setAttribute('class', props.modal ? 'modal__title' : 'Error__title')
  errorTitle.setAttribute('aria-hidden', true)
  errorTitle.textContent = headerText
  errorHeader.appendChild(errorTitle)
  errorInfo.appendChild(errorHeader)

  const errorMain = document.createElement('section')
  errorMain.setAttribute('class', props.modal ? 'modal__body' : 'Error__body')
  if (message) errorMain.appendChild(message)
  errorMain.appendChild(labelValue('Current Time', dayjs().format('M/D/YYYY hh:mm:ss.SSS A')))
  if (requestNumber) errorMain.appendChild(requestNumber)
  if (nodeId) errorMain.appendChild(nodeId)
  errorInfo.appendChild(errorMain)
  if (closeButton) {
    if (props.reload) {
      let controlsDiv = document.createElement('div')
      controlsDiv.setAttribute('class', 'rvt-modal__controls')
      controlsDiv.appendChild(closeButton)
      errorInfo.appendChild(controlsDiv)
    } else {
      errorInfo.appendChild(closeButton)
    }
  }

  return errorInfo
}
